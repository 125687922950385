import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import LogoGraphic from "../images/icons/logo_graphic.png"
import LogoText from "../images/icons/logo_text.png"

import "bootstrap/dist/css/bootstrap.css"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

const Layout = ({ children }) => {
  return (
    <>
      <Navbar className="p-3 px-4" style={{boxShadow: "0 0 5px 2px #60b7a4"}} collapseOnSelect expand="lg">
        <Link to="/">
          <img src={LogoGraphic} alt="" style={{width: "auto", height: "60px", padding: 0, marginRight: 6, marginBottom: 0}}/>
          <img src={LogoText} alt="" style={{width: "auto", height: "40px", padding: 0, marginBottom: 0}}/>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
          <Nav> 
            <Link className="ms-2 me-2 mt-2" style={{color: "gray", fontSize: "13pt", textDecoration: "none"}} activeStyle={{color: "#60b7a4"}} to="/our-story">Our Story</Link>
            <Link className="ms-2 me-2 mt-2" style={{color: "gray", fontSize: "13pt", textDecoration: "none"}} activeStyle={{color: "#60b7a4"}} to="/our-locations">Our Locations</Link>
            <Link className="ms-2 me-2 mt-2" style={{color: "gray", fontSize: "13pt", textDecoration: "none"}} activeStyle={{color: "#60b7a4"}} to="/our-services">Our Services</Link>
            <Link className="ms-2 me-2 mt-2" style={{color: "gray", fontSize: "13pt", textDecoration: "none"}} activeStyle={{color: "#60b7a4"}} to="/client-testimonials">Client Testimonials</Link>
            <Link className="ms-2 mt-2" style={{color: "gray", fontSize: "13pt", textDecoration: "none"}} activeStyle={{color: "#60b7a4"}} to="/contact-us">Contact Us</Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div style={{margin: "0 auto", marginBottom: "60px", maxWidth: 900, padding: "0 1.0875rem 1.45rem"}}>
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
